var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cursor-pointer d-flex align-center font-weight-light",
    on: {
      "click": _vm.share
    }
  }, [_vm._v(" Share "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-share.svg"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }