<template></template>

<script>
import { initGeolocation } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => initGeolocation({ coordinates: [] }) },
        address: { type: String, default: null },
    },
    data: () => ({
        geolocation: initGeolocation({ coordinates: [] }),
    }),
    mounted() {
        this.sync().then(this.update);
    },
    watch: {
        value() {
            this.sync();
        },
        address() {
            this.sync().then(this.update);
        },
    },
    methods: {
        async sync() {
            this.geolocation = initGeolocation(this.value);
        },
        async emit() {
            this.$emit("input", this.geolocation);
        },
        async update() {
            if (this.address) {
                if (!this.geolocation.coordinates[0]) {
                    this.geolocation = await this.$getGeolocationWithKakaoMaps(this.address);
                }
            } else {
                this.geolocation = initGeolocation({ coordinates: [0, 0] });
            }

            this.emit();
        },
    },
};
</script>

<style>
</style>