var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', _vm._b({
    attrs: {
      "title": "쿠폰 사용하기",
      "width": "1080",
      "maxWidth": "1080",
      "eager": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: Object.assign({}, attrs, {
            loading: _vm.loading,
            disabled: _vm.disabled
          }),
          count_total: _vm.count_total,
          count_usable: _vm.count_usable,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": function ($event) {
              _vm.shows = false;
            }
          }
        }, 'v-btn', _vm.btn_tertiary, false), [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', _vm.btn_primary, false), [_vm._v("저장하기")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'u-dialog', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    staticClass: "mx-n3 my-3"
  }, [_c('v-col', {
    staticClass: "pa-3",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0 !important"
    }
  }), _c('form-table', _vm._b({
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'form-table', {
    items: _vm.items,
    order: _vm.value,
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('order-coupons-prices', _vm._b({
    attrs: {
      "order": _vm.value
    }
  }, 'order-coupons-prices', {
    carts: _vm.carts,
    selected: _vm.selected
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }