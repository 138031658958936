<template>
    <client-page>
        <shop-product-visual wide image="/images/shop/overview/visual.jpg" title="Bathroom Fittings"></shop-product-visual>

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">완벽한 핏과 스타일의 결합</h3>
                        <p class="txt txt--sm">욕실은 그저 욕실이 아닙니다. 개인적인 바디 케어, 뷰티, 휴식을 즐기는 곳입니다. 그래서 Villeroy & Boch에서는 믹서 수전을 포함하여 가장 작은 디테일까지 디자인을 고민합니다. 세면대에 최적화되어 있으며 개인 욕실 디자인을 완성합니다. 욕실과 스타일에 완벽하게 어울리는 Villeroy & Boch 믹서 수전을 선택하세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="mt-50px mt-md-100px">
                <v-row>
                    <v-col cols="12" md="auto">
                        <router-link to="#1" class="txt txt--sm d-flex align-center">
                            제품군 알아보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            팁 전수 받기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#3" class="txt txt--sm d-flex align-center">
                            영감 얻기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#4" class="txt txt--sm d-flex align-center">
                            컬렉션 보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-ensemble />
        <overview-range />

        <page-section>
            <v-img src="/images/shop/overview/overview-wide.jpg" />
        </page-section>

        <overview-inspiration />
        <overview-download />

        <page-section>
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <h2 class="tit font-tertiary mb-12px mb-md-16px">어떤 스타일이 당신에게 어울리나요?</h2>
                        <p class="txt txt--sm px-md-28px">눈에 띄게 각진 디자인의 수도꼭지 또는 미니멀리스트 스타일의 세면실, 샤워실 또는 목욕 천국을 어떻게 상상하시나요? 당사의 피팅 컬렉션은 귀하에게 특별한 디자인의 자유를 선사합니다. 또한 Villeroy & Boch 욕실 컬렉션과 완벽하게 조화를 이룹니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-liberty />

        <page-section>
            <v-sheet color="grey lighten-5 py-36px py-md-72px">
                <v-container>
                    <h2 class="tit font-tertiary mb-12px mb-md-16px">클래식한 우아함, 순수함, 모던함 모두</h2>
                    <p class="txt txt--sm">우리의 피팅 컬렉션은 항상 최신 상태입니다. 모든 스타일과 모든 요구에 맞는 제품입니다.</p>
                </v-container>
            </v-sheet>
        </page-section>

        <overview-collections />
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import OverviewEnsemble from "@/components/client/shop/products/overview/overview-ensemble.vue";
import OverviewRange from "@/components/client/shop/products/overview/overview-range.vue";
import OverviewInspiration from "@/components/client/shop/products/overview/overview-inspiration.vue";
import OverviewDownload from "@/components/client/shop/products/overview/overview-download.vue";
import OverviewLiberty from "@/components/client/shop/products/overview/overview-liberty.vue";
import OverviewCollections from "@/components/client/shop/products/overview/overview-collections.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        OverviewEnsemble,
        OverviewRange,
        OverviewInspiration,
        OverviewDownload,
        OverviewLiberty,
        OverviewCollections,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "제품",
                href: "/shop/bathroom-fittings/overview",
            },
            {
                text: "욕실 수전",
                href: "/ideas/bathroom/shop/bathroom-fittings/overview",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>