var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "delivery"
    }
  }, [_c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("배송/교환/반품 정보")])])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "반품"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품신청​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("구매자 책임사유시 반품/배송비 결제​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("발송하기")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("1~2일 이내 택배기사 방문​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품접수완료​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("반품상품 전달​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품완료")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("확인 후 환불처리​")])])], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "교환"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환신청")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("구매자 책임사유시 반품/배송비 결제​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("발송하기")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("1~2일 이내 택배기사 방문​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환접수")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("교환상품 전달​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("상품재배송​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("확인 후 교환​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환완료")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("완료")])])], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "주의사항"
    }
  }), _c('div', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_vm._v(" 미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​​ ")])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_vm._v(" 안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​ ")])], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }