var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__info"
  }, [_c('info-section', [_c('div', {
    staticClass: "tricky-brand-name",
    domProps: {
      "innerHTML": _vm._s(_vm.product.content)
    }
  }), _c('h3', {
    staticClass: "tit font-weight-regular font-tertiary"
  }, [_vm._v(_vm._s(_vm.product.name.split(",")[0]))]), _c('h4', {
    staticClass: "txt txt--sm txt--dark font-weight-light font-secondary"
  }, [_vm._v(_vm._s(_vm.productDetail))]), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('info-rating', _vm._b({}, 'info-rating', {
    product: _vm.product
  }, false))], 1)]), _c('info-section', [_c('info-price', _vm._b({}, 'info-price', {
    product: _vm.product
  }, false))], 1), _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('info-shipping', _vm._b({}, 'info-shipping', {
    product: _vm.product
  }, false))], 1), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_vm._t("form"), _vm._t("actions")], 2), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }