<template>
    <vertical-table v-bind="{ items }" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { initOrder } from "@/assets/variables";

import VerticalTable from "@/components/dumb/vertical-table.vue";

export default {
    components: {
        VerticalTable,
    },
    props: {
        order: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        selected: { type: Array, default: () => [] },
    },
    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["islands", "setting"]),
        items() {
            const totalDeliveryPrice = this.$getDeliveryPrice(this.carts, this.order?.receiver?.geolocation, this.setting, this.userLevel) + this.$getIslandPrice(this.carts, this.islands, this.order?.receiver?.postcode);
            const couponPrice = this.selected?.[0]?.couponPrice || 0;
            const totalPrice = totalDeliveryPrice - couponPrice;

            return [
                { term: "배송금액", data: totalDeliveryPrice },
                { term: "쿠폰할인", data: couponPrice },
                { term: "할인적용금액", data: totalPrice },
            ].map((item) => ({ ...item, data: `${item.data?.format?.() || item.data}원` }));
        },
    },
};
</script>

<style></style>
