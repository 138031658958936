var render = function render(){
  var _vm$summary, _vm$summary$totalCoun, _vm$summary$totalCoun2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "qna"
    }
  }, [_c('div', {
    staticClass: "product-qna"
  }, [_c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("상품문의 (" + _vm._s(((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : (_vm$summary$totalCoun = _vm$summary.totalCount) === null || _vm$summary$totalCoun === void 0 ? void 0 : (_vm$summary$totalCoun2 = _vm$summary$totalCoun.format) === null || _vm$summary$totalCoun2 === void 0 ? void 0 : _vm$summary$totalCoun2.call(_vm$summary$totalCoun)) || 0) + ")")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('inquire-list-search', _vm._b({
    on: {
      "input": function ($event) {
        (_vm.page = 1) && _vm.search();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, 'inquire-list-search', {
    loading: _vm.loading
  }, false))], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "items": _vm.inquires,
      "headers": _vm.headers,
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "expanded": _vm.expanded,
      "mobile-breakpoint": "768"
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v("작성된 문의사항이 없습니다.")];
      },
      proxy: true
    }, {
      key: `item.subject`,
      fn: function (_ref) {
        var _vm$payload;
        var item = _ref.item;
        return [_c('div', [!item.secret || item._user == ((_vm$payload = _vm.payload) === null || _vm$payload === void 0 ? void 0 : _vm$payload._user) ? _c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.subject))]) : _c('span', [_c('v-icon', {
          staticClass: "font-size-16 font-size-md-18 grey--text text--lighten-3 pb-2px pr-4px"
        }, [_vm._v("mdi-lock-outline")]), _vm._v("비밀글입니다")], 1)])];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref2) {
        var _vm$payload2, _vm$payload3;
        var headers = _ref2.headers,
          item = _ref2.item;
        return [!item.secret || item._user == ((_vm$payload2 = _vm.payload) === null || _vm$payload2 === void 0 ? void 0 : _vm$payload2._user) ? _c('td', {
          staticClass: "v-data-table__mobile-row__cell",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-row', [_c('v-col', [_c('div', {
          staticStyle: {
            "white-space": "pre-line",
            "word-break": "break-all"
          }
        }, [_vm._v(_vm._s(item.content))]), item.images && item.images.filter(function (image) {
          return image;
        }) ? _c('div', {
          staticClass: "pt-8px"
        }, [_c('v-row', {
          staticClass: "row--xs"
        }, _vm._l(item.images, function (image, index) {
          return _c('v-col', {
            key: index,
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-img', {
            attrs: {
              "max-width": "200",
              "src": '/res/shop/inquires/' + item._id + '/' + image
            }
          })], 1);
        }), 1)], 1) : _vm._e()]), item._user == ((_vm$payload3 = _vm.payload) === null || _vm$payload3 === void 0 ? void 0 : _vm$payload3._user) ? _c('v-col', {
          staticClass: "pa-1",
          attrs: {
            "cols": "auto"
          }
        }, [_c('inquire-form', _vm._g(_vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "x-small": ""
                }
              }, 'v-btn', Object.assign({}, attrs), false), on), [_c('span', [_vm._v(" 수정하기 ")])])];
            }
          }], null, true)
        }, 'inquire-form', {
          value: item,
          product: _vm.product
        }, false), {
          search: _vm.search
        })), _c('br', {
          staticClass: "d-lg-none"
        }), _c('inquire-remove', _vm._g(_vm._b({
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var attrs = _ref4.attrs,
                on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "x-small": ""
                }
              }, 'v-btn', Object.assign({}, attrs), false), on), [_c('span', [_vm._v(" 삭제하기 ")])])];
            }
          }], null, true)
        }, 'inquire-remove', {
          value: item
        }, false), {
          search: _vm.search
        }))], 1) : _vm._e()], 1), item.reply ? _c('div', {
          staticClass: "d-flex font-weight-medium pt-8px"
        }, [_c('span', {
          staticClass: "pr-8px"
        }, [_vm._v("└")]), _c('div', {
          domProps: {
            "innerHTML": _vm._s(item.reply)
          }
        })]) : _vm._e()], 1) : _vm._e()];
      }
    }, {
      key: `item.user`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.user ? _c('span', [_vm._v(" " + _vm._s(item.user.username.substr(0, 1)) + _vm._s(item.user.username.substr(1).replace(/./g, "*")) + " ")]) : _c('span', [_vm._v("탈퇴회원")])];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.createdAt.toDate()))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.reply ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("답변완료")]) : _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("답변대기")])];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading
  }, false)), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    attrs: {
      "clsss": "row--xs"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('inquire-form', _vm._g(_vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var attrs = _ref8.attrs,
          on = _ref8.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.accessToken
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v(" 문의하기 ")])];
      }
    }])
  }, 'inquire-form', {
    product: _vm.product
  }, false), {
    search: _vm.search
  }))], 1)], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }