var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed",
    attrs: {
      "fixed": "",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = true;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("구매하기")])])], 1)], 1)], 1)]), _c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed-view",
    attrs: {
      "input-value": _vm.shows,
      "fixed": "",
      "height": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-0",
    attrs: {
      "block": "",
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24",
      "color": "grey"
    }
  }, [_vm._v("mdi-chevron-down")])], 1), _c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('div', {
    staticClass: "overflow-x-hidden overflow-y-auto",
    staticStyle: {
      "max-height": "50vh"
    }
  }, [_vm._t("form")], 2), _vm._t("actions")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }