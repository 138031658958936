var render = function render(){
  var _vm$value, _vm$value$thumb, _vm$value2, _vm$value2$flags, _vm$value3, _vm$value3$flags, _vm$value4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item-content', [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "width": "60",
      "aspect-ratio": 1 / 1,
      "src": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$thumb = _vm$value.thumb) === null || _vm$value$thumb === void 0 ? void 0 : _vm$value$thumb.url,
      "contain": ""
    },
    scopedSlots: _vm._u([{
      key: "place-holder",
      fn: function () {
        return [_c('v-icon', [_vm._v("mdi-image-broken-variant")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-col', [_c('div', {
    staticClass: "font-size-14 line-height-135"
  }, [(_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && (_vm$value2$flags = _vm$value2.flags) !== null && _vm$value2$flags !== void 0 && _vm$value2$flags.length ? _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$flags = _vm$value3.flags) === null || _vm$value3$flags === void 0 ? void 0 : _vm$value3$flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.name))]), _c('div', {
    staticClass: "mt-8px mt-md-10px"
  }, [_vm.discountPrice ? _c('div', {
    staticClass: "text-decoration-line-through grey--text text--lighten-1"
  }, [_vm._v(" " + _vm._s(_vm.value.price.format()) + " ")]) : _vm._e(), !_vm.value.sells || _vm.value.stock == 0 ? _c('span', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("품절")]) : _c('div', [_vm.discountRate ? _c('span', {
    staticClass: "font-weight-medium pr-4px"
  }, [_vm._v(_vm._s(_vm.discountRate) + "%")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.salePrice.format()) + "원")])])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }