var render = function render(){
  var _vm$product$thumb, _vm$summary, _vm$summary$reviews, _vm$summary$reviews$f, _vm$summary2, _vm$summary2$inquires, _vm$summary2$inquires2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "scroll-fixed-wrap"
  }, [_c('div', {
    staticClass: "scroll-fixed"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "product-top-fixed"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "width": "48",
      "aspect-ratio": 1 / 1,
      "src": (_vm$product$thumb = _vm.product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('nav-price', _vm._b({}, 'nav-price', {
    product: _vm.product
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.productTopFixedBtn ? [_c('v-btn', {
    staticClass: "min-w-200px",
    attrs: {
      "large": "",
      "color": "grey darken-4"
    },
    on: {
      "click": function ($event) {
        ((_vm.productTopFixedView = true) || true) && (_vm.productTopFixedBtn = false);
      }
    }
  }, [_vm._v("바로구매")])] : [_c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "x-small": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        ((_vm.productTopFixedView = false) || true) && (_vm.productTopFixedBtn = true);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24",
      "color": "grey"
    }
  }, [_vm._v("mdi-close")])], 1)]], 2)], 1), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.productTopFixedView,
      expression: "productTopFixedView"
    }],
    staticClass: "my-24px"
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.productTopFixedView,
      expression: "productTopFixedView"
    }],
    staticClass: "product-top-fixed-view"
  }, [_c('div', {
    staticClass: "overflow-hidden"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('info-shipping', _vm._b({}, 'info-shipping', {
    product: _vm.product
  }, false))], 1)]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._t("form")], 2), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._t("actions")], 2)], 1)], 1)])], 1), _c('scrollactive', {
    staticClass: "v-tabs",
    attrs: {
      "offset": _vm.$vuetify.breakpoint.lgAndUp ? 186 : 100,
      "scrollOffset": _vm.$vuetify.breakpoint.lgAndUp ? 186 : 100,
      "highlightFirstItem": true,
      "active-class": "v-tab--active"
    }
  }, [_c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#review"
    }
  }, [_vm._v(" 상품평"), _c('span', {
    staticClass: "d-none d-lg-inline-block"
  }, [_vm._v("(" + _vm._s(((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : (_vm$summary$reviews = _vm$summary.reviews) === null || _vm$summary$reviews === void 0 ? void 0 : (_vm$summary$reviews$f = _vm$summary$reviews.format) === null || _vm$summary$reviews$f === void 0 ? void 0 : _vm$summary$reviews$f.call(_vm$summary$reviews)) || 0) + ")")])]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#detail"
    }
  }, [_vm._v("상품상세")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#qna"
    }
  }, [_vm._v(" 상품문의"), _c('span', {
    staticClass: "d-none d-lg-inline-block"
  }, [_vm._v("(" + _vm._s(((_vm$summary2 = _vm.summary) === null || _vm$summary2 === void 0 ? void 0 : (_vm$summary2$inquires = _vm$summary2.inquires) === null || _vm$summary2$inquires === void 0 ? void 0 : (_vm$summary2$inquires2 = _vm$summary2$inquires.format) === null || _vm$summary2$inquires2 === void 0 ? void 0 : _vm$summary2$inquires2.call(_vm$summary2$inquires)) || 0) + ")")])]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#delivery"
    }
  }, [_vm._v("배송/교환/반품 정보")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }