<template>
    <v-sheet max-width="640">
        <v-slide-group v-model="categoryCode" center-active show-arrows>
            <template v-for="(item, index) in items">
                <v-slide-item :key="item?._id" :value="item?.code || null" class="ma-2" v-slot="{ active }">
                    <v-card :class="{ 'v-sheet--outlined primary': active || item?.code == categoryCode }" @click.prevent.stop="routerPush(item)">
                        <v-img :src="item?.visual?.src" width="80" height="80">
                            <template #placeholder>
                                <v-overlay absolute color="grey lighten-3">
                                    <v-icon>mdi-image-broken-variant</v-icon>
                                </v-overlay>
                            </template>
                            <div class="category-label">
                                <template v-if="index == 0">
                                    <span> 전체보기 </span>
                                </template>
                                <template v-else>
                                    <span> {{ item?.name }} </span>
                                </template>
                            </div>
                        </v-img>
                    </v-card>
                </v-slide-item>
            </template>
        </v-slide-group>
    </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import { initShopCategory } from "@/assets/variables";

export default {
    data: () => ({
        categoryCode: null,
    }),

    computed: {
        ...mapState(["categories"]),
        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode) ?? initShopCategory();
        },
        parent() {
            return this.categories.find((item) => item._id == this.category?._parent) ?? this.categories_children.find((item) => item._id == this.category?._parent) ?? this.categories_grandChildren.find((item) => item._id == this.category?._parent) ?? this.categories_granderChildren.find((item) => item._id == this.category?._parent) ?? initShopCategory();
        },
        items() {
            if (0 < (this.category?.children?.length || 0)) {
                return [this.category, ...this.category?.children];
            }

            if (!this.category) return [this.category, ...this.categories];

            switch (this.category.depth) {
                case 1: {
                    return [this.parent, ...this.categories];
                }
                case 2: {
                    return [this.parent, ...this.categories_children.filter(({ _parent }) => _parent == this.category._parent)];
                }
                case 3: {
                    return [this.parent, ...this.categories_grandChildren.filter(({ _parent }) => _parent == this.category._parent)];
                }
                case 4: {
                    return [this.parent, ...this.categories_granderChildren.filter(({ _parent }) => _parent == this.category._parent)];
                }
                default:
                    return [];
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        categories() {
            this.init();
        },
        "$route.query.category"() {
            this.init();
        },
    },
    methods: {
        init() {
            this.categoryCode = this.$route.query.category;
        },
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
        routerPush(item) {
            let { path } = this.$route;

            let { ...query } = this.$route.query;

            query.category = item?.code || null;
            if (!query.category) delete query.category;

            delete query.page;

            this.$router.push({ path, query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .category-label {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 4px 6px;
    background-color: rgba(0, 0, 0, 0.1);

    font-size: 12px;
    text-align: center;
}
</style>
