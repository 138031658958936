<template>
    <div class="product-intro__info">
        <!-- <info-section hideDivider class="my-n16px my-sm-n24px">
            <v-row align="center" class="row--xxs">
                <v-col cols="auto">
                    <info-groups v-bind="{ product }" />
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <info-like v-bind="{ product }" />
                        </v-col>
                        <v-col cols="auto">
                            <info-share v-bind="{ product }" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </info-section> -->

        <info-section>
            <!-- 브랜드명 -->
            <div v-html="product.content" class="tricky-brand-name" />
            <!-- <info-flags class="txt txt--sm txt--dark font-weight-bold mb-4px mb-md-8px" v-bind="{ product }" /> -->

            <!-- 상품명 -->
            <!-- <h3 class="tit font-weight-regular font-tertiary">{{ product.name }} {{ product.code || "" }}</h3> -->
            <h3 class="tit font-weight-regular font-tertiary">{{ product.name.split(",")[0] }}</h3>
            <h4 class="txt txt--sm txt--dark font-weight-light font-secondary">{{ productDetail }}</h4>

            <div class="pt-16px pt-md-24px">
                <info-rating v-bind="{ product }" />
            </div>
        </info-section>

        <!-- 상품 가격 -->
        <info-section>
            <info-price v-bind="{ product }" />
        </info-section>

        <!-- 배송정보 -->
        <info-section class="txt txt--xs">
            <info-shipping v-bind="{ product }" />
        </info-section>

        <div class="d-none d-lg-block">
            <slot name="form" />
            <slot name="actions" />
        </div>

        <slot name="foot" />
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

import InfoSection from "./info/info-section.vue";

import InfoGroups from "./info/info-groups.vue";
import InfoLike from "./info/info-like.vue";
import InfoShare from "./info/info-share.vue";

import InfoFlags from "./info/info-flags.vue";
import InfoRating from "./info/info-rating.vue";

import InfoPrice from "./info/info-price.vue";
import InfoShipping from "./info/info-shipping.vue";

export default {
    components: {
        InfoSection,

        InfoGroups,
        InfoLike,
        InfoShare,

        InfoFlags,
        InfoRating,

        InfoPrice,
        InfoShipping,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        bottomNav: false,
    }),
    computed: {
        productDetail() {
            let names = (this.product?.name || [])?.split(",");
            return names.splice(1, names?.length)?.join(",");
        },
    },
};
</script>

<style lang="scss" scoped>
.tricky-brand-name {
    > ::v-deep(table) {
        > caption {
            display: none;
        }
        > tbody {
            > tr {
                border: none !important;
                > td {
                    display: inline-block;
                    padding: 0 !important;
                    line-height: 2;
                    color: #44474a !important;
                    word-spacing: 2px;
                    position: relative;
                    &:first-child {
                        display: none;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 4px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 1px;
                        color: inherit;
                        background-color: currentColor;
                    }
                }
                &:not(:first-of-type) {
                    display: none;
                }
            }
        }
    }
}
</style>
