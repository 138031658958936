<template>
    <info-section class="txt txt--xs" v-if="items.length">
        <tit-wrap-x-small title=" 연관상품" />
        <v-select
            :value="product._id"
            v-bind="{ items }"
            item-value="_id"
            dense
            outlined
            hide-details
            :menu-props="{
                offsetY: true,
                contentClass: 'select-menu',
                closeOnClick: true,
                closeOnContentClick: true,
            }"
            class="rounded-0"
        >
            <template #selection="{ item }">
                {{ item.name }}
            </template>
            <template #item="{ item }">
                <v-list-item :to="`/shop/products/${item._id}`" :disabled="!item.sells || item.stock == 0">
                    <related-group-item-content :value="item" />
                </v-list-item>
            </template>
        </v-select>
    </info-section>
</template>

<script>
import InfoSection from "@/components/client/shop/products/view/info/info-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import RelatedGroupItemContent from "./related-group/related-group-item-content.vue";

export default {
    components: {
        InfoSection,
        TitWrapXSmall,
        RelatedGroupItemContent,
    },
    props: {
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            return this.product?.relatedGroupItems || [];
        },
    },
};
</script>

<style lang="scss" scoped></style>
