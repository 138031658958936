var render = function render(){
  var _vm$user, _vm$user$point, _vm$user$point$format, _vm$levelDiscountPric, _vm$levelDiscountPric2, _vm$order, _vm$order$point, _vm$order$point$forma;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "hasCustomAside": ""
    }
  }, [_c('page-section', {
    staticClass: "page-section--last"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--xxl"
  }, [_c('v-col', {
    staticClass: "border-lg-r",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("주문/결제")])]), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('order-carts', _vm._b({
    ref: "order-carts",
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'order-carts', {
    mode: _vm.mode,
    carts: _vm.carts
  }, false))], 1)], 2), _vm.mode == 'normal' ? [_c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "구매자 정보입력"
    }
  }), _c('order-sender', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order.sender,
      callback: function ($$v) {
        _vm.$set(_vm.order, "sender", $$v);
      },
      expression: "order.sender"
    }
  })], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "수취인 정보입력"
    }
  }), _c('order-receiver', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  })], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "요청사항"
    }
  }), _c('vertical-form-table', {
    attrs: {
      "items": [Object.assign({
        key: 'requestMessage',
        term: '요청사항',
        type: 'textarea'
      }, _vm.attrs_input, {
        placeholder: '요청사항을 입력하세요'
      })]
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  })], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "쿠폰할인"
    }
  }), _c('order-coupons', _vm._b({
    on: {
      "input": _vm.updateCoupons__carts
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'order-coupons', {
    carts: _vm.carts
  }, false))], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "적립금사용"
    }
  }, [_c('span', {
    staticClass: "d-flex align-center txt txt--sm"
  }, [_c('span', {
    staticClass: "pr-10px pr-md-16px"
  }, [_vm._v("보유 적립금")]), _c('span', {
    staticClass: "grey--text text--darken-4"
  }, [_c('strong', {
    staticClass: "txt--lg"
  }, [_vm._v(_vm._s(((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$point = _vm$user.point) === null || _vm$user$point === void 0 ? void 0 : (_vm$user$point$format = _vm$user$point.format) === null || _vm$user$point$format === void 0 ? void 0 : _vm$user$point$format.call(_vm$user$point)) || 0))]), _vm._v(" 원 ")])])]), _c('order-point', {
    attrs: {
      "max": _vm.maxUsablePoint
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order.point,
      callback: function ($$v) {
        _vm.$set(_vm.order, "point", $$v);
      },
      expression: "order.point"
    }
  })], 1)], 2)] : _vm._e(), _c('page-section', {
    staticClass: "page-section--sm pt-0 pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "결제방법"
    }
  }), _c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--no-hover"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.order.paymentMethod,
      callback: function ($$v) {
        _vm.$set(_vm.order, "paymentMethod", $$v);
      },
      expression: "order.paymentMethod"
    }
  }, [_c('v-radio', _vm._b({
    attrs: {
      "name": "payment_method",
      "value": "card",
      "label": "신용카드"
    }
  }, 'v-radio', Object.assign({}, _vm.radio_secondary, _vm.$attrs), false)), _vm.mode == 'normal' ? [_c('v-radio', _vm._b({
    attrs: {
      "name": "payment_method",
      "value": "account",
      "label": "무통장 입금"
    }
  }, 'v-radio', Object.assign({}, _vm.radio_secondary, _vm.$attrs), false))] : _vm._e()], 2)], 1)])])])], 1)], 2)], 2), _c('v-col', {
    staticClass: "position-relative pt-0px pt-lg-40px",
    attrs: {
      "lg": "3"
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm pt-0 pb-0 position-sticky"
  }, [_c('v-card', {
    staticClass: "mt-xl-12px"
  }, [_c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "결제정보"
    }
  }), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0 !important"
    }
  }), _vm.mode == 'normal' ? [_c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("상품금액")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.productPrice.format()) + " 원 ")])])], 1)], 1), _vm.discountPrice ? _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("상품할인")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.discountPrice.format()) + " 원 ")])])], 1)], 1) : _vm._e(), _vm.levelDiscountPrice ? _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("등급할인")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s((_vm$levelDiscountPric = _vm.levelDiscountPrice) === null || _vm$levelDiscountPric === void 0 ? void 0 : (_vm$levelDiscountPric2 = _vm$levelDiscountPric.format) === null || _vm$levelDiscountPric2 === void 0 ? void 0 : _vm$levelDiscountPric2.call(_vm$levelDiscountPric)) + " 원 ")])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("배송비")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s((_vm.servicePrice + _vm.deliveryPrice).format()) + " 원 ")])])], 1)], 1), _vm.islandPrice ? _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("추가배송비")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.islandPrice.format()) + " 원 ")])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("사용적립금")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : (_vm$order$point = _vm$order.point) === null || _vm$order$point === void 0 ? void 0 : (_vm$order$point$forma = _vm$order$point.format) === null || _vm$order$point$forma === void 0 ? void 0 : _vm$order$point$forma.call(_vm$order$point)) + " 원 ")])])], 1)], 1), _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("쿠폰할인")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.couponPrice.format()) + " 원 ")])])], 1)], 1)] : _vm._e(), _c('div', {
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs txt--dark",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', [_vm._v("총 결제금액")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('span', {
    staticClass: "d-inline-flex flex-wrap align-center"
  }, [_c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _c('span', {
    staticClass: "pl-4px"
  }, [_vm._v("원")])])])], 1)], 1), _c('div', {
    staticClass: "pt-16px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.pay
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("결제하기")])], 1)], 1)], 1)], 2)])], 1)], 1)], 1)], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1), _c('modal-order-bank', {
    ref: "modal-order-bank",
    attrs: {
      "totalPrice": _vm.totalPrice
    },
    on: {
      "pay": _vm.submit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }), _c('order-geolocation', {
    attrs: {
      "address": _vm.order.receiver.address1
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order.receiver.geolocation,
      callback: function ($$v) {
        _vm.$set(_vm.order.receiver, "geolocation", $$v);
      },
      expression: "order.receiver.geolocation"
    }
  }), _c('nice-pay', {
    ref: "nicePay"
  }), _c('nice-pay-u', {
    ref: "nicePayU"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }