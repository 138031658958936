<template>
    <page-section>
        <div class="grey lighten-5 py-36px py-md-72px mb-40px mb-md-80px">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary grey lighten-5">영감을 얻으세요</span>
                </div>
            </v-container>
        </div>
        <v-container>
            <swiper v-bind="{ options }">
                <template v-for="item in items">
                    <swiper-slide :key="item.title">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" md="5" order="2" order-md="1">
                                <v-card class="h-md-400px d-md-flex align-center">
                                    <div>
                                        <div class="tit mb-12px mb-md-16px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm text-pre-wrap">
                                            {{ item.text, }}
                                        </p>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="7" order="1" order-md="2">
                                <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                    <v-responsive :aspect-ratio="674 / 504" />
                                    <div class="swiper-controler">
                                        <v-row align="center" class="row--xs">
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="swiper-pagination" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                </template>
            </swiper>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "크롬",
                text: "시원한 은빛 크롬보다 더 빛나는 마감재는 없습니다. 메탈릭한 포인트로 매트한 느낌과 잘 어울리네요. 그 광택은 가벼운 목재, 천연석, 슬레이트 느낌이나 콘크리트 느낌의 타일과 같은 천연 소재와 매우 잘 어울립니다.\r\n색상 측면에서 Chrome은 녹색 음영과 잘 조화를 이루며 결과적으로 질감의 자연스러운 주제를 포착합니다.",
                image: "/images/shop/overview/overview-inspiration-slide-1.webp",
            },
            {
                title: "브러시드 니켈",
                text: "이보다 더 현대적이고 순수할 수는 없습니다. Brushed Nickel은 부드러운 측면에서 금속성 느낌을 보여줍니다. 크롬과 달리 이 마감은 따뜻한 색조를 띠고 있습니다. 욕실에 차분하고 부드러운 반짝임을 선사합니다.\r\n브러시드 니켈은 연한 청록색부터 무연탄색까지 수중 세계의 색상과 특히 잘 어울립니다.",
                image: "/images/shop/overview/overview-inspiration-slide-2.webp",
            },
            {
                title: "브러시드 골드",
                text: "브러시드 골드는 욕실에서 최고의 눈길을 사로잡는 제품입니다. 금은 자동으로 화려해 보일 필요가 없으며, 따뜻한 대위법으로 방에 편안함과 가정적인 느낌을 더할 수 있습니다.\r\n브러시드 골드는 섬세한 파스텔 톤과 잘 어울립니다. 또는 무연탄, 에메랄드 그린 또는 코발트 블루와 같은 어두운 톤으로 광채를 강화하고 로맨틱하고 개인적인 분위기를 조성합니다.",
                image: "/images/shop/overview/overview-inspiration-slide-3.webp",
            },
            {
                title: "블랙 매트",
                text: "블랙 매트는 시각적 효과가 설득력이 있기 때문에 계속 유지되는 트렌드입니다. 욕실에 강조되어 사용되면 블랙은 신비롭고 미니멀하며 현대적으로 보입니다.\r\n강한 대비는 밝은 띠의 시각적 균형을 제공하고 남성적인 성격을 부여합니다. 동시에 검은색 피팅은 목재를 훨씬 더 잘 돋보이게 합니다.",
                image: "/images/shop/overview/overview-inspiration-slide-4.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.inspiration-text {
    position: relative;
    z-index: 3;
}
.swiper {
    position: relative;
    &::before {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: -100vw;
        z-index: 2;
    }
}
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: inherit;
    }
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
</style>