var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "payForm",
    attrs: {
      "name": "payForm",
      "method": "post",
      "action": "/api/plugins/nice/pay/response",
      "accept-charset": "euc-kr"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "PayMethod",
      "value": "CARD,BANK"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "GoodsName"
    },
    domProps: {
      "value": _vm.goodsName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Amt"
    },
    domProps: {
      "value": _vm.amt
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "MID"
    },
    domProps: {
      "value": _vm.merchantID
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Moid"
    },
    domProps: {
      "value": _vm.moid
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerName"
    },
    domProps: {
      "value": _vm.buyerName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerEmail"
    },
    domProps: {
      "value": _vm.buyerEmail
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BuyerTel"
    },
    domProps: {
      "value": _vm.buyerTel
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ReturnURL"
    },
    domProps: {
      "value": _vm.returnURL
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "VbankExpDate",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "NpLang",
      "value": "KO"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "GoodsCl",
      "value": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "TransType",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "CharSet",
      "value": "utf-8"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ReqReserved",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "EdiDate"
    },
    domProps: {
      "value": _vm.ediDate
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "SignData"
    },
    domProps: {
      "value": _vm.hashString
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }