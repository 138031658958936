var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/shop/visual-sample.jpg",
      "title": "NEW HIT"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-140px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/shop/new-hit/new-hit-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("The Antao")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" Experience the magical energy of nature with our new Antao "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" collection - inspired by taut drops, brings the beauty and "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" tranquility of nature right into your home. ")])])], 1)], 1)], 1), _c('new-hit-overview'), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/shop/new-hit/new-hit-02.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("Infinity Showers")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" The Villeroy & Boch Infinity shower collection makes your daily ritual a pleasant experience. Pamper yourself with different types of radiation and enjoy your very personal shower pleasure. ")])])], 1)], 1)], 1), _c('new-hit-overview'), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/shop/new-hit/new-hit-03.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("Guest bathing solutions")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" Stylish guest bathroom with artis washbasins and collaro wardrobes. Inspiring solutions for small rooms. ")])])], 1)], 1)], 1), _c('new-hit-tabs')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }