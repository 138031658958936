<template id="modal-template">
    <u-dialog v-model="show" v-if="setting" title="무통장 입금" persistent width="600">
        <!-- S: 입금하실 계좌정보 -->
        <page-section class="page-section--xs py-0">
            <template #containerImmersive>
                <tit-wrap-x-small title="입금하실 계좌정보"></tit-wrap-x-small>
                <v-card rounded="md" color="grey lighten-5">
                    <div class="pa-16px">
                        <div>
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 입금하실 계좌 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4">{{ banks.find((bank) => bank.code == setting.shop.accountBankCode)?.name }}</span>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-8px">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 예금주명 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4">{{ setting.shop.accountHolder }}</span>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-8px">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 계좌번호 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4">{{ setting.shop.accountNumber }}</span>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-8px">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 입금 금액 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4">{{ totalPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card>

                <p v-show="false" class="txt txt--sm red--text">
                    ※위의 계좌로 무통장 입금 신청 후 <b>5시간 내에 정확한 금액을 입금 진행</b>하셔야 합니다.
                    <br />
                    5시간 이내 입금되지 않을시 주문이 취소됩니다.
                </p>
            </template>
        </page-section>
        <!-- E: 입금하실 계좌정보 -->

        <!-- S: 입금 계좌정보 -->
        <!-- 개발확인 -->
        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-form-section :items="deposits">
                    <template slot="입금은행">
                        <v-select v-model="order.account.accountBankCode" v-bind="{ ...attrs_input }" :items="banks" item-text="name" item-value="code" placeholder="입금은행" />
                    </template>
                    <template slot="예금주명">
                        <v-text-field v-model="order.account.accountHolder" v-bind="{ ...attrs_input }" />
                    </template>
                    <template slot="계좌번호">
                        <v-text-field v-model="order.account.accountNumber" v-bind="{ ...attrs_input }" type="number" />
                    </template>
                </tit-form-section>
            </template>
        </page-section>
        <!-- E: 입금 계좌정보 -->

        <!-- S: 현금영수증 신청하기 -->
        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-wrap-x-small title="현금영수증 신청하기"></tit-wrap-x-small>
                <v-radio-group v-model="order.cashReceipt.enabled" row hide-details>
                    <v-radio v-bind="{ ...radio_secondary, ...$attrs }" label="신청안함" :value="false" />
                    <v-radio v-bind="{ ...radio_secondary, ...$attrs }" label="현금영수증 신청하기" :value="true" />
                </v-radio-group>
            </template>
        </page-section>
        <!-- E: 현금영수증 신청하기 -->

        <!-- S: 발급받을 전화번호 또는 사업자 번호 -->
        <!-- 개발확인 -->
        <page-section v-if="order.cashReceipt.enabled" class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-form-section :items="cashs">
                    <template slot="발급받을 전화번호 또는 사업자 번호">
                        <v-text-field v-model="order.cashReceipt.phone" v-bind="{ ...attrs_input }" placeholder="“-” 없이 숫자만 입력 부탁드립니다." />
                    </template>
                </tit-form-section>

                <div v-if="order.cashReceipt.enabled" class="pt-6px">
                    <div class="font-size-12 red--text">010-1234-1234는 01012341234로 입력</div>
                </div>
            </template>
        </page-section>
        <!-- E: 발급받을 전화번호 또는 사업자 번호 -->

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-large" @click="close">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="pay">결제</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>

    <!-- <transition v-if="show && setting" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal modal--7">
                    <div class="modal__head modal__head--no-border-bottom">
                        <h1 class="modal__title">무통장 입금</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="modal-order-form">
                            <div class="row">
                                <h2 class="title">입금하실 계좌정보</h2>
                                <div role="table" class="table">
                                    <div role="rowgroup">
                                        <div role="row">
                                            <div role="columnheader">입금하실 계좌</div>
                                            <div role="cell">{{ banks.find((bank) => bank.code == setting.shop.accountBankCode )?.name }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">예금주명</div>
                                            <div role="cell">{{ setting.shop.accountHolder }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">계좌번호</div>
                                            <div role="cell">{{ setting.shop.accountNumber }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">입금 금액</div>
                                            <div role="cell"><b class="color-red">{{ totalPrice.format() }}원</b></div>
                                        </div>
                                    </div>
                                </div>
                                <p class="caption color-red">
                                    ※위의 계좌로 무통장 입금 신청 후 <br class="mo"><b>5시간 내에 정확한 금액을 입금 진행</b>하셔야 합니다.
                                    <br>
                                    5시간 이내 입금되지 않을시 주문이 취소됩니다.
                                </p>
                            </div>
                            <div class="row">
                                <h2 class="title">입금 계좌정보</h2>
                                <div role="table" class="table">
                                    <div role="rowgroup">
                                        <div role="row">
                                            <div role="columnheader">입금은행</div>
                                            <div role="cell">
                                                <select v-model="order.account.accountBankCode" class="select" name="">
                                                    <option v-for="bank in banks" :value="bank.code">{{ bank.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">예금주명</div>
                                            <div role="cell">
                                                <input v-model="order.account.accountHolder" class="input" type="text" name="" value="">
                                            </div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">계좌번호</div>
                                            <div role="cell">
                                                <input v-model="order.account.accountNumber" class="input input--wide" type="number" name="" value="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="add-form-box">
                                    <div class="add-form-box__head">
                                        <span class="add-form-box__title">현금영수증 신청하기</span>
                                        <div class="radio-wrap">
                                            <label class="radio radio--check">
                                                <input v-model="order.cashReceipt.enabled" class="input" type="radio" name="radio" :value="false">
                                                <i class="icon"></i>
                                                <span class="text">신청안함</span>
                                            </label>
                                            <label class="radio radio--check">
                                                <input v-model="order.cashReceipt.enabled" class="input" type="radio" name="radio" :value="true">
                                                <i class="icon"></i>
                                                <span class="text">현금영수증 신청하기</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="order.cashReceipt.enabled" class="add-form-box__body">
                                        <span class="input-flex-title">발급받을 전화번호 또는 사업자 번호</span>
                                        <div class="input-flex">
                                            <input v-model="order.cashReceipt.phone" class="input" type="text" name="" value="">
                                            <p>
                                                “-” 없이 숫자만 입력 부탁드립니다. <br>
                                                010-1234-1234 는 01012341234로 입력
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <div class="modal__buttons modal__buttons--narrow">
                            <button class="button button--border-primary font-nanum-m" type="button" @click="close"><span>취소하기</span></button>
                            <button class="button button--primary font-nanum-m" type="button" @click="pay"><span>결제하기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition> -->
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { btn_primary, btn_tertiary, radio_secondary } from "@/assets/variables";
import banks from "@/plugins/shop-banks.json";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";

const deposits = [
    {
        term: "입금은행",
        type: "text",
    },
    {
        term: "예금주명",
        type: "text",
    },
    {
        term: "계좌번호",
        type: "text",
    },
].map((item) => ({ ...item, ...attrs_input }));

const cashs = [
    {
        term: "발급받을 전화번호 또는 사업자 번호",
        type: "text",
    },
].map((item) => ({ ...item, ...attrs_input }));

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapXSmall,
        TitFormSection,
    },
    props: {
        value: { type: Object, default: null },
        totalPrice: { type: Number, default: 0 },
    },
    data: () => {
        return {
            btn_primary,
            btn_tertiary,
            radio_secondary,
            attrs_input,

            show: false,
            order: null,
            setting: null,
            banks,
            deposits,
            cashs,
        };
    },
    computed: {
        user() {
            return this.$store.state?.user;
        },
    },
    mounted() {
        this.order = this.value;
        this.init();
    },
    watch: {
        show() {
            this.order.account = {
                accountNumber: this.user?.bank?.accountNumber,
                accountHolder: this.user?.bank?.accountHolder,
                accountBankCode: this.user?.bank?.code,
            };
        },
        value() {
            this.order = this.value;
        },
    },
    methods: {
        async init() {
            try {
                await this.getSetting();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        open() {
            this.show = true;
            document.querySelector("html").setAttribute("data-modal", "opened");
        },
        close() {
            this.show = false;
            document.querySelector("html").removeAttribute("data-modal");
        },
        pay() {
            this.$emit("pay");
            this.close();
        },
        getSetting() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/setting");

                    this.setting = res.data.setting;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
