<template>
    <client-page>
        <page-section class="page-section--last">
            <template v-if="!loading">
                <div class="text-center">
                    <div class="mb-18px mb-md-24px">
                        <icon-check v-if="success" />
                        <v-icon v-else size="60px" color="primary">mdi-alert</v-icon>
                    </div>

                    <template v-if="order?.paymentMethod == 'account'">
                        <!-- 무통장입금 -->
                        <div class="tit">주문이 정상적으로 완료 되었습니다.</div>
                        <div class="mt-8px">
                            <v-card color="grey lighten-5" class="d-inline-block">
                                <div class="pa-20px py-md-12px">
                                    <div class="divider-group">
                                        <ul>
                                            <li class="font-size-md-16 grey--text text--darken-4">입금계좌 : {{ setting?.shop?.accountNumber }}</li>
                                            <li class="font-size-md-16 grey--text text--darken-4">예금주 : {{ setting?.shop?.accountHolder }}</li>
                                            <li class="font-size-md-16 grey--text text--darken-4">은행명 : {{ setting?.shop?.accountBankText }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </v-card>
                        </div>
                        <div class="txt txt--xs txt--light mt-8px">
                            2023년 10월 10일 15:00까지 입금 확인이 되어야 주문이 완료됩니다.<br />
                            입금기한 내에 입금 확인이 되지 않을 경우, 주문이 취소됩니다.<br />
                            자세한 주문내역 정보는 마이페이지&gt;주문배송조회 페이지에서 확인하실 수 있습니다.
                        </div>
                        <!-- // 무통장입금 -->
                    </template>
                    <template v-else>
                        <!-- 신용카드 -->
                        <div v-if="success" class="tit">주문/결제가 완료되었습니다.</div>
                        <div v-else class="tit">주문/결제에 실패하였습니다.</div>
                        <div v-if="success" class="txt txt--sm txt--dark mt-8px">결제가 완료되었습니다. 마이페이지에서 내용을 확인하세요.</div>
                        <div v-else class="txt txt--sm txt--dark mt-8px">[{{ resCode }}] {{ resMessage }}</div>
                        <!-- // 신용카드 -->
                    </template>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col v-if="success" cols="6" sm="auto">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/mypage/orders" class="v-size--xx-large w-100 min-w-sm-200px">주문내역 조회하기</v-btn>
                            </v-col>
                            <v-col v-else cols="6" sm="auto">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/" class="v-size--xx-large w-100 min-w-sm-200px">확인</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
            <v-fade-transition>
                <v-progress-circular v-show="loading" size="100" width="5" />
            </v-fade-transition>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { btn_primary } from "@/assets/variables";

import IconCheck from "@/components/client/icons/icon-check.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        IconCheck,
        ClientPage,
        PageSection,
    },
    data: () => ({
        btn_primary,

        order: {},
        setting: {},

        resCode: null,
        resMessage: null,

        loading: false,
    }),
    computed: {
        success() {
            return this.resCode == "3001";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        // ...mapActions("metaPixel", ["fbq"]),
        // ...mapActions("naverWcs", ["wcs_do_cnv"]),
        // ...mapActions("enlipleTracker", ["callMtmOnOrderResult"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.resCode = `${this.$route.query.resCode || 3001}`;
            this.resMessage = this.$route.query.resMessage || null;

            try {
                const { order } = await api.v1.me.orders.get({ _id: this.$route.query._order });
                this.order = order;

                if (order.paymentMethod == "account") {
                    const { setting } = await api.v1.setting.get();
                    this.setting = setting;
                }
                // this.fbq({
                //     type: "Purchase",
                //     options: { value: order.totalPrice, currency: "KRW" },
                // });
                // this.wcs_do_cnv({
                //     type: "1",
                //     amount: order.totalPrice,
                // });

                // this.enlipleTracker();
            } finally {
                this.loading = false;
            }
        },

        enlipleTracker() {
            /** @type {import('@/store/enliple-tracker').EnlipleTrackerCallMtmProps & import('@/store/enliple-tracker').EnlipleTrackerOrderResultProps} */
            let props = {
                convType: "etc",
                conversion: {
                    product: this.order?.purchases?.map?.((item) => ({
                        productCode: item?.code || "",
                        productName: item?.name || "",
                        price: `${item?.salePrice || ""}`,
                        qty: `${item?.amount || ""}`,
                    })),
                    ordCode: this.order?.orderNo || "",
                    totalPrice: `${this.order?.totalPrice || ""}`,
                    totalQty: `${this.order?.purchases?.reduce?.((sum, { amount }) => sum + amount, 0) || ""}`,
                },
            };
            this.callMtmOnOrderResult(props);
        },
    },
};
</script>
