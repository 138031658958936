<template>
    <vertical-form-table v-model="form" v-bind="{ mode, items }" @input="emit">
        <template #배송지선택>
            <v-radio-group v-model="mode" row hide-details>
                <v-radio v-bind="{ ...radio_secondary, ...$attrs }" value="default" label="기본배송지" :disabled="!defaultReceiver?._id" />
                <v-radio v-bind="{ ...radio_secondary, ...$attrs }" value="form" label="신규배송지" />
                <destination-select-dialog v-model="form.receiver" @input="emit">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" class="v-size--xx-small">배송지 목록</v-btn>
                    </template>
                </destination-select-dialog>
            </v-radio-group>
        </template>
        <template #배송지명 v-if="mode != 'default'">
            <v-text-field v-model="form.receiver.title" v-bind="attrs_input" placeholder="배송지명을 입력하세요." @input="emit" />
            <div class="pt-8px">
                <v-checkbox v-model="form.receiver.isDefault" v-bind="{ ...checkbox_secondary, ...$attrs }" label="기본배송지로 선택" @input="emit" />
            </div>
        </template>
    </vertical-form-table>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables/attrs";
import { initDestination, initOrder, initOrderReceiver } from "@/assets/variables/inits";
import { btn_tertiary, radio_secondary, checkbox_secondary } from "@/assets/variables/attrs";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import DestinationSelectDialog from "../destinations/destination-select-dialog.vue";

export default {
    components: {
        VerticalFormTable,
        DestinationSelectDialog,
    },
    props: {
        value: { type: Object, default: initOrder },
    },
    data: () => ({
        btn_tertiary,
        radio_secondary,
        checkbox_secondary,

        form: initOrder(),
        defaultReceiver: initDestination({ geolocation: { coordinates: [] } }),

        mode: "default",
        attrs_input,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            const items = [];

            items.push({ term: "배송지선택" });
            items.push({ key: "receiver.title", term: "배송지명", type: "text", required: true });
            items.push({ key: "receiver.name", term: "이름", type: "text", required: true, placeholder: "이름을 입력하세요." });
            items.push({ key: "receiver.phone", term: "연락처", type: "phone", required: true });
            items.push({ key: "receiver", term: "주소", type: "address", required: true, persistentPlaceholder: true, btnAttrs: { large: true, color: "primary" } });

            return items.map((item) => ({ ...item, outlined: true }));
        },
        isDefaultItem() {
            const { _id: _default } = this.defaultReceiver || {};
            return (_default && _default == this.form?.receiver?._id) || false;
        },
    },
    mounted() {
        this.sync().then(this.init);
    },
    watch: {
        value() {
            this.sync();
        },
        mode() {
            switch (this.mode) {
                case "default": {
                    this.form = initOrder({ ...this.form, receiver: this.defaultReceiver });
                    this.emit();
                    break;
                }
                case "form": {
                    this.form = initOrder({ ...this.form, receiver: initOrderReceiver({ geolocation: { coordinates: [] } }) });
                    this.emit();
                    break;
                }
            }
        },
        isDefaultItem() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                this.defaultReceiver = (await api.v1.me.destinations.gets({ headers: { limit: 1 } }))?.destinations[0] ?? initDestination({ geolocation: { coordinates: [] } });
                this.form = initOrder({ ...this.value, receiver: this.defaultReceiver });
                this.emit();
            } finally {
                this.loading = false;
            }
        },

        async sync() {
            this.form = initOrder(this.value);

            if (this.isDefaultItem) {
                if (this.mode != "default") this.mode = "default";
            } else if (this.form?.receiver?._id) {
                if (this.mode != "neither") this.mode = "neither";
            } else {
                if (this.mode != "form") this.mode = "form";
            }
        },

        async emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
