var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/shop/overview/visual.jpg",
      "title": "Bathroom Fittings"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("완벽한 핏과 스타일의 결합")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실은 그저 욕실이 아닙니다. 개인적인 바디 케어, 뷰티, 휴식을 즐기는 곳입니다. 그래서 Villeroy & Boch에서는 믹서 수전을 포함하여 가장 작은 디테일까지 디자인을 고민합니다. 세면대에 최적화되어 있으며 개인 욕실 디자인을 완성합니다. 욕실과 스타일에 완벽하게 어울리는 Villeroy & Boch 믹서 수전을 선택하세요.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#1"
    }
  }, [_vm._v(" 제품군 알아보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" 팁 전수 받기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_vm._v(" 영감 얻기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#4"
    }
  }, [_vm._v(" 컬렉션 보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('overview-ensemble'), _c('overview-range'), _c('page-section', [_c('v-img', {
    attrs: {
      "src": "/images/shop/overview/overview-wide.jpg"
    }
  })], 1), _c('overview-inspiration'), _c('overview-download'), _c('page-section', [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("어떤 스타일이 당신에게 어울리나요?")]), _c('p', {
    staticClass: "txt txt--sm px-md-28px"
  }, [_vm._v("눈에 띄게 각진 디자인의 수도꼭지 또는 미니멀리스트 스타일의 세면실, 샤워실 또는 목욕 천국을 어떻게 상상하시나요? 당사의 피팅 컬렉션은 귀하에게 특별한 디자인의 자유를 선사합니다. 또한 Villeroy & Boch 욕실 컬렉션과 완벽하게 조화를 이룹니다.")])])], 1)], 1)], 1), _c('overview-liberty'), _c('page-section', [_c('v-sheet', {
    attrs: {
      "color": "grey lighten-5 py-36px py-md-72px"
    }
  }, [_c('v-container', [_c('h2', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("클래식한 우아함, 순수함, 모던함 모두")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("우리의 피팅 컬렉션은 항상 최신 상태입니다. 모든 스타일과 모든 요구에 맞는 제품입니다.")])])], 1)], 1), _c('overview-collections')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }