var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('h2', {
    staticClass: "tit font-weight-regular font-tertiary text-center mb-18px mb-md-24px"
  }, [_vm._v("믹서 탭과 세라믹의 완벽한 앙상블")]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "order-md": "2",
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-sheet', {
    attrs: {
      "max-width": "700",
      "color": "transparent"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": item,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "swiper-pagination"
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order-md": "1",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order": "3",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt-24px mt-md-40px mb-12px mb-md-16px txt txt--sm txt--dark text-center font-weight-medium"
  }, [_vm._v("우린 이걸 '최고의 조합'이라 부르기로 했어요!")]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "570"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("특히 조화로운 전반적인 모습과 최적의 기능을 위해 믹서 탭과 욕실 세라믹이 완벽하게 어울리도록 합니다. 세라믹의 주요 디자인 요소는 믹서 탭의 디자인에 반영됩니다.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }