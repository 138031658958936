<template>
    <vertical-form-table v-model="form" v-bind="{ items }" @input="emit">
        <template slot="주문 쿠폰">
            <order-coupons-default v-model="form" v-bind="{ carts }" @input="emit">
                <template #activator="{ attrs, on, count_total, count_usable }">
                    <v-row align="center" class="row--xs">
                        <v-col cols="12" sm="auto">
                            <v-currency-field :value="couponPrice__default" outlined hide-details type="number" suffix="원" class="v-currency-field w-xl-440px" readonly @input="emit">
                                <template #append-outer>
                                    <v-btn v-bind="{ ...btn_secondary, ...attrs }" x-large class="min-w-120px" v-on="on">보유쿠폰 사용</v-btn>
                                </template>
                            </v-currency-field>
                        </v-col>
                        <v-col cols="auto">
                            <span class="txt txt--xs txt--light pl-sm-8px">(사용가능 : {{ count_usable }}장 / 보유 {{ count_total }}장)</span>
                        </v-col>
                    </v-row>
                </template>
            </order-coupons-default>
        </template>
    </vertical-form-table>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { btn_secondary, initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import OrderCouponsDefault from "./order-coupons-default.vue";

const items = [{ term: "주문 쿠폰" }];

export default {
    components: {
        VerticalFormTable,
        OrderCouponsDefault,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initOrder(),

        items,
        btn_secondary,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["setting"]),
        couponPrice__default() {
            const otherCoupons = this.form.coupons.filter((coupon) => coupon.usage.target.price != USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value);
            return this.$getCouponPrice(this.carts, this.form.coupons, this.value?.receiver?.geolocation, this.setting, this.userLevel) - this.$getCouponPrice(this.carts, otherCoupons, this.value?.receiver?.geolocation, this.setting, this.userLevel);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initOrder(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
