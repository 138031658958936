<template>
    <!-- 총 상품 금액 -->
    <page-section class="page-section--xs pb-0">
        <v-row align="center">
            <v-col cols="auto">
                <div class="txt txt--xs">총 상품 금액</div>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <span class="d-flex align-center line-height-1 primary--text">
                    <div class="font-size-20 font-size-lg-36 font-weight-medium">{{ totalPrice.format() }}</div>
                    <span class="pl-2px">원</span>
                </span>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { mapGetters } from "vuex";

import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        carts: { type: Array, default: () => [] },
    },
    computed: {
        ...mapGetters(["getSalePrice"]),
        totalPrice() {
            return this.carts.reduce((totalPrice, cart) => {
                return totalPrice + this.getSalePrice(cart) * cart.amount;
            }, 0);
        },
    },
};
</script>

<style>
</style>