<template>
    <client-page>
        <shop-product-visual wide image="/images/shop/visual-sample.jpg" title="NEW HIT"></shop-product-visual>

        <page-section class="pt-40px pt-md-140px">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img src="/images/shop/new-hit/new-hit-01.jpg" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">The Antao</h2>
                        <p class="txt txt--sm">
                            Experience the magical energy of nature with our new Antao <br class="d-none d-md-block" />
                            collection - inspired by taut drops, brings the beauty and <br class="d-none d-md-block" />
                            tranquility of nature right into your home.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <new-hit-overview />

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/shop/new-hit/new-hit-02.jpg" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">Infinity Showers</h2>
                        <p class="txt txt--sm">
                            The Villeroy &amp; Boch Infinity shower collection makes your daily ritual a pleasant experience. Pamper yourself with different types of radiation and enjoy your very personal shower pleasure.
                            <!-- <br class="d-none d-md-block" /> -->
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <new-hit-overview />

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img src="/images/shop/new-hit/new-hit-03.jpg" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">Guest bathing solutions</h2>
                        <p class="txt txt--sm">
                            Stylish guest bathroom with artis washbasins and collaro wardrobes. Inspiring solutions for small rooms.
                            <!-- <br class="d-none d-md-block" /> -->
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <new-hit-tabs></new-hit-tabs>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import NewHitOverview from "@/components/client/shop/new-hit/new-hit-overview.vue";
import NewHitTabs from '@/components/client/shop/new-hit/new-hit-tabs.vue';

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        NewHitOverview,
        NewHitTabs,
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>