<template>
    <page-section>
        <v-container>
            <v-row class="row--lg">
                <v-col cols="12" md="3">
                    <div class="section-title">
                        <h2 class="tit font-tertiary font-weight-regular mb-18px mb-md-24px">Product overview</h2>
                        <div class="mt-18px mt-md-24px">
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <v-btn x-large icon outlined class="swiper-button swiper-button--prev">
                                        <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-large icon outlined class="swiper-button swiper-button--next">
                                        <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="9">
                    <swiper class="swiper" v-bind="{ options }">
                        <template v-for="item in 6">
                            <swiper-slide :key="item">
                                <shop-product-card />
                            </swiper-slide>
                        </template>
                    </swiper>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
};
</script>