<template>
    <div class="cursor-pointer d-flex align-center font-weight-light" @click="share">
        Share
        <v-img src="/images/icon/icon-share.svg" class="ml-8px" />
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
    methods: {
        share() {
            navigator.share({
                text: this.product.name,
                url: window.location.href,
            });
        },
    },
};
</script>

<style>
</style>