<template>
    <!-- <v-breadcrumbs :items="breadcrumbs" class="pa-0"> -->
    <v-breadcrumbs class="pa-0">
        <template v-slot:divider>
            <v-icon color="#333">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                <span class="font-size-12 font-weight-light">
                    {{ item.text }}
                </span>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    
}
</script>