<template>
    <client-page hasCustomAside>
        <page-section class="page-section--last">
            <v-container class="container--lg">
                <v-row class="row--xxl">
                    <v-col cols="12" lg="" class="border-lg-r">
                        <div class="tit-wrap">
                            <h2 class="tit">주문/결제</h2>
                        </div>
                        <!-- S: 장바구니 -->
                        <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <order-carts v-model="order" v-bind="{ mode, carts }" ref="order-carts" />
                            </template>
                        </page-section>
                        <!-- E: 장바구니 -->
                        <template v-if="mode == 'normal'">
                            <!-- S: 구매자정보 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="구매자 정보입력"> </tit-wrap-line>
                                    <order-sender v-model="order.sender" @input="emit" />
                                </template>
                            </page-section>
                            <!-- E: 구매자정보 -->

                            <!-- S: 수취인정보 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="수취인 정보입력"> </tit-wrap-line>

                                    <order-receiver v-model="order" @input="emit" />
                                </template>
                            </page-section>
                            <!-- E: 수취인정보 -->

                            <!-- S: 요청사항 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="요청사항"> </tit-wrap-line>
                                    <vertical-form-table v-model="order" :items="[{ key: 'requestMessage', term: '요청사항', type: 'textarea', ...attrs_input, placeholder: '요청사항을 입력하세요' }]" @input="emit" />
                                </template>
                            </page-section>
                            <!-- E: 요청사항 -->

                            <!-- S: 쿠폰할인 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="쿠폰할인"></tit-wrap-line>
                                    <order-coupons v-model="order" v-bind="{ carts }" @input="updateCoupons__carts" />
                                </template>
                            </page-section>
                            <!-- E: 쿠폰할인 -->

                            <!-- S: 적립금사용 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="적립금사용">
                                        <span class="d-flex align-center txt txt--sm">
                                            <span class="pr-10px pr-md-16px">보유 적립금</span>
                                            <span class="grey--text text--darken-4">
                                                <strong class="txt--lg">{{ user?.point?.format?.() || 0 }}</strong> 원
                                            </span>
                                        </span>
                                    </tit-wrap-line>
                                    <order-point v-model="order.point" :max="maxUsablePoint" @input="emit" />
                                </template>
                            </page-section>
                            <!-- E: 적립금사용 -->
                        </template>

                        <!-- S: 결제방법 -->
                        <page-section class="page-section--sm pt-0 pb-0">
                            <template slot="containerImmersive">
                                <!-- head -->
                                <tit-wrap-line noDivider title="결제방법"> </tit-wrap-line>

                                <v-simple-table class="v-data-table--default v-data-table--no-hover">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-radio-group v-model="order.paymentMethod" row hide-details>
                                                    <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="card" label="신용카드" />
                                                    <template v-if="mode == 'normal'">
                                                        <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="account" label="무통장 입금" />
                                                    </template>
                                                    <!-- <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="easy" label="간편결제" /> -->
                                                </v-radio-group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </template>
                        </page-section>
                        <!-- E: 결제방법 -->
                    </v-col>
                    <v-col lg="3" class="position-relative pt-0px pt-lg-40px">
                        <!-- S: 결제정보 -->
                        <page-section class="page-section--sm pt-0 pb-0 position-sticky">
                            <v-card class="mt-xl-12px">
                                <div>
                                    <!-- head -->
                                    <tit-wrap-line noDivider title="결제정보"></tit-wrap-line>

                                    <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                                    <template v-if="mode == 'normal'">
                                        <div class="py-16px px-20px border-b">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">상품금액</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ productPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b" v-if="discountPrice">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">상품할인</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ discountPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b" v-if="levelDiscountPrice">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">등급할인</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ levelDiscountPrice?.format?.() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">배송비</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ (servicePrice + deliveryPrice).format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b" v-if="islandPrice">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">추가배송비</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ islandPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">사용적립금</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ order?.point?.format?.() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="py-16px px-20px border-b">
                                            <v-row align="center" class="row--xs txt txt--xs">
                                                <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                    <span class="txt--dark">쿠폰할인</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7">
                                                    <span> {{ couponPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </template>

                                    <div class="py-16px px-20px border-b">
                                        <v-row align="center" class="row--xs txt txt--xs txt--dark">
                                            <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                <span>총 결제금액</span>
                                            </v-col>
                                            <v-col cols="7" sm="9" md="10" xl="7">
                                                <span class="d-inline-flex flex-wrap align-center">
                                                    <strong class="font-size-18 font-size-md-20">{{ totalPrice.format() }}</strong>
                                                    <span class="pl-4px">원</span>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </div>

                                    <div class="pt-16px">
                                        <v-row class="row--xs">
                                            <!-- <v-col cols="12" sm="6" lg="12">
                                                <v-btn block outlined color="primary" to="/" class="v-size--xx-large" v-bind="{ loading }">취소하기</v-btn>
                                            </v-col> -->
                                            <v-col cols="12">
                                                <v-btn block color="grey darken-4" class="v-size--xx-large" v-bind="{ loading }" @click="pay">결제하기</v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-card>
                        </page-section>
                        <!-- E: 결제정보 -->
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <v-fade-transition>
            <v-overlay v-show="loading" color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>

        <!-- 무통장 입금 모달 -->
        <modal-order-bank ref="modal-order-bank" v-model="order" @pay="submit" :totalPrice="totalPrice"> </modal-order-bank>

        <!-- 쿠폰 사용 -->
        <!-- <div>
            쿠폰 사용 (상품 / 주문쿠폰)
            <input type="number" onkeydown="return event.keyCode !== 69" :value="couponPrice" />
            <span class="currency-unit">원</span>
            <button class="button button--mouse" type="button" @click="openModalUseCoupon">
                <span>쿠폰사용</span>
            </button>
            <span class="left-text">사용가능 쿠폰 : {{ $refs["modal-use-coupon"] ? $refs["modal-use-coupon"].coupons.length : 0 }}장</span>
        </div> -->

        <!-- 쿠폰사용 모달 -->
        <!-- <modal-use-coupon ref="modal-use-coupon" v-model="coupons" :carts="carts" :deliveryPrice="deliveryPrice"> </modal-use-coupon> -->

        <order-geolocation v-model="order.receiver.geolocation" :address="order.receiver.address1" @input="emit" />

        <nice-pay ref="nicePay" />
        <nice-pay-u ref="nicePayU" />
    </client-page>
</template>

<script>
import api from "@/api";
import { radio_secondary } from "@/assets/variables";
import { mapActions, mapGetters, mapState } from "vuex";

import detect from "@/plugins/detect.js";
import { attrs_input } from "@/assets/variables";
import { initOrder } from "@/assets/variables/inits";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

import NicePay from "@/components/plugins/nice/nice-pay.vue";
import NicePayU from "@/components/plugins/nice/nice-pay-u.vue";

import OrderCarts from "@/components/client/shop/order/order-carts/order-carts.vue";
import OrderPoint from "@/components/client/shop/order/order-point.vue";
import OrderSender from "@/components/client/shop/order/order-sender.vue";
import OrderCoupons from "@/components/client/shop/order/order-coupons/order-coupons.vue";
import OrderReceiver from "@/components/client/shop/order/order-receiver.vue";
import OrderGeolocation from "@/components/client/shop/order/order-geolocation.vue";
import DestinationSelectDialog from "@/components/client/shop/destinations/destination-select-dialog.vue";

import ModalOrderBank from "../../../components/client/shop/modal-order-bank.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        VerticalFormTable,

        NicePay,
        NicePayU,

        OrderCarts,
        OrderPoint,
        OrderSender,
        OrderCoupons,
        OrderReceiver,
        OrderGeolocation,
        DestinationSelectDialog,

        ModalOrderBank,
    },

    data: () => ({
        radio_secondary,

        detect,

        loading: false,

        pickup: { _carts: [] },
        carts: [],

        order: initOrder(),
        attrs_input,
    }),

    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["user", "islands", "setting"]),

        mode() {
            return this.$route.query.mode || "normal";
        },

        ///////////////////////////////////////////////////////////////////////
        // 액세스 토큰
        ///////////////////////////////////////////////////////////////////////
        accessToken() {
            return this.$store.state.accessToken;
        },

        ///////////////////////////////////////////////////////////////////////
        // 우편번호
        ///////////////////////////////////////////////////////////////////////
        postcode() {
            return this.order.receiver.postcode;
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송가능지역
        ///////////////////////////////////////////////////////////////////////
        isShippingAvailable() {
            return this.$getIsShippingAvailable(this.carts, this.order?.receiver, this.setting);
        },

        ///////////////////////////////////////////////////////////////////////
        // 상품가 합계
        ///////////////////////////////////////////////////////////////////////
        productPrice() {
            return this.$getProductPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 설치서비스 합계
        ///////////////////////////////////////////////////////////////////////
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 할인가 합계
        ///////////////////////////////////////////////////////////////////////
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 등급할인 합계
        ///////////////////////////////////////////////////////////////////////
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.carts, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 쿠폰할인금액 합계
        ///////////////////////////////////////////////////////////////////////
        couponPrice() {
            return this.$getCouponPrice(this.carts, this.order.coupons, this.orrder?.receiver?.geolocation, this.setting, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송비 합계
        ///////////////////////////////////////////////////////////////////////
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.order?.receiver?.geolocation, this.setting, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 도서산간비용 합계
        ///////////////////////////////////////////////////////////////////////
        islandPrice() {
            return this.$getIslandPrice(this.carts, this.islands, this.postcode);
        },

        ///////////////////////////////////////////////////////////////////////
        // 최대사용가능포인트
        ///////////////////////////////////////////////////////////////////////
        maxUsablePoint() {
            const priceLeft = this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice - this.levelDiscountPrice || 0;
            const pointLeft = this.user?.point || 0;
            return [priceLeft, pointLeft].sort((a, b) => a - b).shift();
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 결제 금액
        ///////////////////////////////////////////////////////////////////////
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.levelDiscountPrice - this.couponPrice - this.order.point;
        },
    },
    mounted() {
        this.reroute().then(this.init);
    },
    watch: {
        accessToken() {
            this.reroute();
        },
        isShippingAvailable() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
            }
        },
    },
    methods: {
        ...mapActions(["getUser", "getIslands", "getShipping__everyAsset"]),

        openModalUseCoupon() {
            this.$refs["modal-use-coupon"].open();
        },

        openModalOrderBank() {
            this.$refs["modal-order-bank"].open();
        },

        emit() {
            this.order = initOrder({
                ...this.order,
                isTargetPayment: this.mode == "target",
            });
        },

        async reroute() {
            try {
                if (!this.accessToken) throw new Error("로그인 후 사용하실 수 있습니다");
            } catch (error) {
                this.$handleError(error);
                this.$router.replace("/login");
            }
        },

        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                switch (this.mode) {
                    case "target": {
                        if (!this.$store.state.pickup__target.length) {
                            alert("구매할 상품을 선택해주세요");
                            this.$router.go(-1);
                            return;
                        }

                        const _carts = this.$store.state.pickup__target;
                        this.order._carts = _carts;

                        const { carts } = await api.v1.me.carts.gets({ params: { _carts } });
                        this.carts = carts;

                        this.getUser().then(() =>
                            this.$nextTick(() => {
                                this.order = initOrder({
                                    ...this.order,

                                    sender: {
                                        ...(this.order?.sender || {}),
                                        ...(this.user || {}),
                                    },

                                    receiver: {
                                        ...(this.order?.receiver || {}),
                                        ...(this.user || {}),
                                    },
                                });
                            })
                        );

                        this.getIslands();
                        this.getShipping__everyAsset();
                        break;
                    }
                    case "normal":
                    default: {
                        if (!this.$store.state.pickup.length) {
                            alert("구매할 상품을 선택해주세요");
                            this.$router.go(-1);
                            return;
                        }

                        const _carts = this.$store.state.pickup;
                        this.order._carts = _carts;

                        const { carts } = await api.v1.me.carts.gets({ params: { _carts } });
                        this.carts = carts;

                        this.getUser().then(() =>
                            this.$nextTick(() => {
                                this.order = initOrder({
                                    ...this.order,

                                    sender: {
                                        ...(this.order?.sender || {}),
                                        ...(this.user || {}),
                                    },
                                });
                            })
                        );

                        this.getIslands();
                        this.getShipping__everyAsset();
                        break;
                    }
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.order = initOrder(this.order);
                this.loading = false;
            }
        },

        async validates() {
            try {
                switch (this.mode) {
                    case "target": {
                        break;
                    }
                    case "normal":
                    default: {
                        if (!this.order?.receiver?.name) throw new Error("수취인명을 입력해주세요");
                        if (!this.order?.receiver?.phone) throw new Error("수취인 연락처를 입력해주세요");
                        if (!this.order?.receiver?.address2) throw new Error("수취인 주소를 입력해주세요");

                        const hasAnUncombinableCoupon = this.order?.coupons?.some?.((coupon) => !coupon?.usage?.combinable) || false;
                        const isCouponsCountMoreThan1 = 1 < this.order?.coupons?.length || 0;
                        if (hasAnUncombinableCoupon && isCouponsCountMoreThan1) throw new Error("중복사용이 불가능한 쿠폰이 있습니다.");

                        if (!this.order?.receiver?._id) {
                            if (!this.order?.receiver?.title) throw new Error("배송지명을 입력해주세요");
                        }
                        break;
                    }
                }

                return true;
            } catch (error) {
                this.$handleError(error);
                return false;
            }
        },

        async pay() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
                return;
            }

            if (!(await this.validates())) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                switch (this.mode) {
                    case "target": {
                        break;
                    }
                    case "normal":
                    default: {
                        const { receiver } = this.order || {};
                        if (receiver && !receiver?._id) {
                            const { destination } = await api.v1.me.destinations.post(receiver);
                            this.order.receiver = destination;
                        }
                        break;
                    }
                }

                switch (this.order.paymentMethod) {
                    case "account": {
                        this.openModalOrderBank();
                        break;
                    }
                    case "card": {
                        this.submit();
                        break;
                    }
                    case "easy": {
                        this.submit();
                        break;
                    }
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async submit() {
            try {
                let { ...form } = this.order;

                form._coupons = form.coupons.map(({ _id }) => _id);
                delete form.coupons;

                let { order } = await api.v1.shop.orders.post(form);

                if (order.totalPrice) {
                    switch (order.paymentMethod) {
                        case "account": {
                            order = (await api.v1.shop.orders.process.post(order))?.order;
                            this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                            break;
                        }
                        case "card": {
                            this.$refs.nicePay.amt = order.totalPrice;
                            this.$refs.nicePay.goodsName = this.carts[0].product.name;
                            this.$refs.nicePay.moid = order.orderNo;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.buyerTel = order.sender.phone;
                            this.$refs.nicePay.nicepayStart();
                            break;
                        }
                        case "easy": {
                            this.$refs.nicePayU.buyerUsername = order.sender.username;
                            this.$refs.nicePayU.buyerName = order.sender.name;
                            this.$refs.nicePayU.buyerTel = order.sender.phone;
                            this.$refs.nicePayU.buyerEmail = order.sender.email;
                            this.$refs.nicePayU.moid = order.orderNo;
                            this.$refs.nicePayU.amt = order.totalPrice;
                            this.$refs.nicePayU.goodsName = this.carts[0].product.name;
                            this.$refs.nicePayU.receiverName = order.receiver.name;
                            this.$refs.nicePayU.receiverTel = order.receiver.phone;
                            this.$refs.nicePayU.receiverPostcode = order.receiver.postcode;
                            this.$refs.nicePayU.receiverAddress1 = order.receiver.address1;
                            this.$refs.nicePayU.receiverAddress2 = order.receiver.address2;

                            this.$refs.nicePayU.submit();
                            break;
                        }
                    }
                } else {
                    order = (await api.v1.shop.orders.process.post(order))?.order;
                    this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async updateCoupons__carts() {
            if (this.loading) return;
            else this.loading = true;

            this.emit();

            this.$nextTick(async () => {
                try {
                    await this.$refs["order-carts"].emit();
                } finally {
                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        overflow: initial;
    }
}
.position-sticky {
    top: 40px;
}
</style>
