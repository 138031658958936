<template>
    <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
        <v-container>
            <h2 class="tit font-weight-regular font-tertiary text-center mb-18px mb-md-24px">믹서 탭과 세라믹의 완벽한 앙상블</h2>

            <v-row justify="center" align="center" class="row--lg">
                <v-col order-md="2" cols="12" md="auto">
                    <v-sheet max-width="700" color="transparent">
                        <swiper class="swiper" v-bind="{ options }">
                            <template v-for="item in items">
                                <swiper-slide :key="item">
                                    <img :src="item" alt="" class="d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                        <div class="swiper-pagination" />
                    </v-sheet>
                </v-col>
                <v-col order-md="1" cols="auto">
                    <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--prev">
                        <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                    </v-btn>
                </v-col>
                <v-col order="3" cols="auto">
                    <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--next">
                        <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                    </v-btn>
                </v-col>
            </v-row>

            <div class="mt-24px mt-md-40px mb-12px mb-md-16px txt txt--sm txt--dark text-center font-weight-medium">우린 이걸 '최고의 조합'이라 부르기로 했어요!</div>
            <v-sheet color="transparent" max-width="570" class="mx-auto">
                <p class="txt txt--sm text-center">특히 조화로운 전반적인 모습과 최적의 기능을 위해 믹서 탭과 욕실 세라믹이 완벽하게 어울리도록 합니다. 세라믹의 주요 디자인 요소는 믹서 탭의 디자인에 반영됩니다.</p>
            </v-sheet>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            //
        ],
        options: {
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.swiper-pagination {
    position: static;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    ::v-deep {
        .swiper-pagination-bullet {
            background-color: rgba(0, 0, 0, 0.2) !important;

            &-active {
                background-color: #007aff !important;
            }
        }
    }
}
</style>