var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title white"
  }, [_c('div', {
    staticClass: "tit font-tertiary text-center"
  }, [_vm._v("All products")])])])], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('v-container', [_c('product-list-filter', _vm._b({}, 'product-list-filter', {
    filterings: _vm.filterings
  }, false)), _c('v-row', {
    staticClass: "row--contents flex-xl-nowrap"
  }, [_c('v-col', [_c('div', {
    staticClass: "mb-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--lg txt--dark"
  }, [_vm._v(_vm._s(_vm.summary.totalCount.format()) + " 개")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "txt txt--sm txt--dark",
    attrs: {
      "width": "96"
    }
  }, [_vm._v("Sort By:")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('product-list-sort-select')], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.products, function (product, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "4",
        "lg": "3"
      }
    }, [_c('shop-product-card', {
      attrs: {
        "value": product
      }
    })], 1)];
  })], 2), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }