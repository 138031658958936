var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-btn-toggle', [_c('v-btn', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("Collaro Bathroom Furniture")]), _c('v-btn', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("Artis attachment washbasin")])], 1), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.products, function (product, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "4",
        "lg": "3"
      }
    }, [_c('shop-product-card', {
      attrs: {
        "value": product
      }
    })], 1)];
  })], 2), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }