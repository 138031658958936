var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-18px mb-md-24px"
  }, [_vm._v("Product overview")]), _c('div', {
    staticClass: "mt-18px mt-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button swiper-button--prev",
    attrs: {
      "x-large": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button swiper-button--next",
    attrs: {
      "x-large": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1)], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(6, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('shop-product-card')], 1)];
  })], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }