var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "max-width": "640"
    }
  }, [_c('v-slide-group', {
    attrs: {
      "center-active": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.categoryCode,
      callback: function ($$v) {
        _vm.categoryCode = $$v;
      },
      expression: "categoryCode"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-slide-item', {
      key: item === null || item === void 0 ? void 0 : item._id,
      staticClass: "ma-2",
      attrs: {
        "value": (item === null || item === void 0 ? void 0 : item.code) || null
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var _item$visual;
          var active = _ref.active;
          return [_c('v-card', {
            class: {
              'v-sheet--outlined primary': active || (item === null || item === void 0 ? void 0 : item.code) == _vm.categoryCode
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.routerPush(item);
              }
            }
          }, [_c('v-img', {
            attrs: {
              "src": item === null || item === void 0 ? void 0 : (_item$visual = item.visual) === null || _item$visual === void 0 ? void 0 : _item$visual.src,
              "width": "80",
              "height": "80"
            },
            scopedSlots: _vm._u([{
              key: "placeholder",
              fn: function () {
                return [_c('v-overlay', {
                  attrs: {
                    "absolute": "",
                    "color": "grey lighten-3"
                  }
                }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('div', {
            staticClass: "category-label"
          }, [index == 0 ? [_c('span', [_vm._v(" 전체보기 ")])] : [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]], 2)])], 1)];
        }
      }], null, true)
    })];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }