<template>
    <page-section>
        <v-container>
            <v-btn-toggle>
                <v-btn small>Collaro Bathroom Furniture</v-btn>
                <v-btn small>Artis attachment washbasin</v-btn>
            </v-btn-toggle>

            <v-row class="row--lg">
                <template v-for="(product, index) in products">
                    <v-col :key="index" cols="6" sm="4" lg="3">
                        <shop-product-card :value="product" />
                    </v-col>
                </template>
            </v-row>

            <div class="product-lists__pagination">
                <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
            </div>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";

export default {
    components: {
        PageSection,
        ShopProductCard,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    > .v-btn {
        margin: 0 var(--grid-gutter);
        padding: var(--btn-padding-xxl);
        border-left-width: thin !important;
        background-color: #fff !important;

        &--active {
            background-color: var(--v-primary-base) !important;
            color: #fff;
            &::before {
                opacity: 0 !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-btn-toggle {
        margin-bottom: 60px;
    }
}
</style>