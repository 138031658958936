<template>
    <v-sheet>
        <v-bottom-navigation fixed height="auto" class="product-bottom-fixed">
            <div class="w-100 pa-12px">
                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="shows = true">
                            <span class="white--text">구매하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-bottom-navigation>

        <v-bottom-navigation :input-value="shows" fixed height="auto" class="product-bottom-fixed-view">
            <v-btn block color="transparent" class="rounded-0" @click="shows = false">
                <v-icon size="24" color="grey">mdi-chevron-down</v-icon>
            </v-btn>
            <div class="w-100 pa-12px">
                <div class="overflow-x-hidden overflow-y-auto" style="max-height: 50vh">
                    <slot name="form" />
                </div>

                <slot name="actions" />
            </div>
        </v-bottom-navigation>
    </v-sheet>
</template>

<script>
import { btn_primary } from "@/assets/variables";

export default {
    data: () => ({
        btn_primary,

        shows: false,
    }),
    watch: {
        "$route.path"() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
