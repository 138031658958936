<template>
    <v-simple-table dense class="v-data-table--default v-data-table--simple-mobile v-data-table--no-hover text-md-center">
        <thead>
            <tr>
                <template v-if="mode == 'normal'">
                    <th style="width: auto" class="text-center">상품정보</th>
                    <th style="width: 12%" class="text-center">판매자</th>
                    <th style="width: 10%" class="text-center">배송비</th>
                    <th style="width: 10%" class="text-center">할인</th>
                    <th style="width: 15%" class="text-center">상품금액(할인포함)</th>
                </template>
                <template v-if="mode == 'target'">
                    <th style="width: 15%" class="text-center">개인결제번호</th>
                    <th style="width: auto" class="text-center">개인결제명</th>
                    <th style="width: 15%" class="text-center">결제금액</th>
                </template>
            </tr>
        </thead>
        <tbody>
            <template v-for="({ deliveryMessage, totalServicePrice, totalDeliveryPrice, productGroups, shippingCode, shippingCodeText, shippingCouponPrice, isShippingAvailable, shippingAreas }, shippingGroupIndex) in shippingGroups">
                <template v-for="({ _product, product, carts, totalDiscountPrice, productPrice, totalPrice, shippingChargeType }, productGroupIndex) in productGroups">
                    <tr :key="_product">
                        <template v-if="mode == 'normal'">
                            <td class="text-left px-0px px-md-20px">
                                <cart-item-info v-bind="{ product, carts }" />
                            </td>
                            <td v-if="productGroupIndex == 0" :rowspan="productGroups.length">브랜드</td>
                            <td v-if="productGroupIndex == 0" :rowspan="productGroups.length">
                                <div class="d-block d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">{{ shippingCodeText }}</div>
                                <div class="d-none d-md-block">{{ shippingCodeText }}</div>

                                <template v-if="shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value">
                                    {{ totalServicePrice + totalDeliveryPrice - shippingCouponPrice ? `${(totalServicePrice + totalDeliveryPrice - shippingCouponPrice)?.format?.()}원` : "무료배송" }}
                                </template>

                                <template v-if="!isShippingAvailable">
                                    <v-dialog width="320">
                                        <template #activator="{ attrs, on }">
                                            <v-icon small dense v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
                                        </template>
                                        <v-card>
                                            <v-card-title>배송가능지역</v-card-title>
                                            <v-card-text>
                                                {{ shippingAreas.join(" / ") || "전체" }}
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </template>

                                <v-tooltip bottom v-if="deliveryMessage">
                                    <template #activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                    </template>
                                    <span>{{ deliveryMessage }}</span>
                                </v-tooltip>

                                <cart-item-coupons-shipping v-model="shippingGroups[shippingGroupIndex].coupons" v-bind="{ order: value, carts, shippingCode }" @input="emit" ref="cart-item-coupons">
                                    <template #activator="{ attrs, on }">
                                        <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on" x-small class="mt-2">쿠폰사용</v-btn>
                                    </template>
                                </cart-item-coupons-shipping>
                            </td>
                            <td style="position: relative">
                                <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">할인</div>
                                <div>{{ totalDiscountPrice?.format?.() }}원</div>
                                <cart-item-coupons-products v-model="productGroups[productGroupIndex].coupons" v-bind="{ order: value, carts, product }" @input="emit" ref="cart-item-coupons">
                                    <template #activator="{ attrs, on }">
                                        <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on" x-small class="mt-2">쿠폰사용</v-btn>
                                    </template>
                                </cart-item-coupons-products>
                            </td>
                            <td>
                                <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">상품금액(할인포함)</div>
                                <v-row no-gutters align="center">
                                    <v-col cols="auto" md="12" order-md="2">
                                        <strong class="d-inline-flex align-center font-size-18 font-size-md-20 grey--text text--darken-4"> {{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                    </v-col>
                                    <v-col v-if="productPrice != totalPrice" cols="auto" md="12" order-md="1">
                                        <span class="d-md-block font-size-14 text-decoration-line-through grey--text text--lighten-1 pl-8px pl-md-0">{{ productPrice.format() }}원</span>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                        <template v-if="mode == 'target'">
                            <td class="text-left px-0px px-md-20px text-center">
                                <span>{{ product.code }} </span>
                            </td>
                            <td class="text-left px-0px px-md-20px text-center">
                                <span>{{ product.name }} </span>
                            </td>
                            <td class="text-left px-0px px-md-20px text-center">
                                <span> {{ totalPrice.format() }}원 </span>
                            </td>
                        </template>
                    </tr>
                </template>
            </template>
        </tbody>
    </v-simple-table>
</template>

<script>
import { btn_secondary, initOrder, SHIPPING_OPTION_CHARGE_TYPES, USAGE_TARGET_PRICES } from "@/assets/variables";
import { mapGetters, mapState } from "vuex";

import CartItemInfo from "./cart-item-info.vue";
import CartItemCouponsProducts from "./cart-item-coupons-products.vue";
import CartItemCouponsShipping from "./cart-item-coupons-shipping.vue";

export default {
    components: {
        CartItemInfo,
        CartItemCouponsProducts,
        CartItemCouponsShipping,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        mode: { type: String, default: "normal" },
    },
    data: () => ({
        shippingGroups: [],

        btn_secondary,

        loading: false,
        SHIPPING_OPTION_CHARGE_TYPES,
    }),
    computed: {
        ...mapGetters(["shippingCodes", "userLevel"]),
        ...mapState(["islands", "setting"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.shippingGroups = this.$getCartShippingGroups(this.carts).map((shippingGroup) => {
                const productGroups = shippingGroup.productGroups.map((productGroup) => {
                    const coupons = this.value.coupons.filter(({ _product }) => _product == productGroup?._product);
                    const totalDiscountPrice = this.$getDiscountPrice(productGroup.carts) + this.$getCouponPrice(productGroup.carts, coupons, this.value?.receiver?.geolocation, this.setting, this.userLevel) + this.$getLevelDiscountPrice(productGroup.carts, this.userLevel);
                    const productPrice = this.$getProductPrice(productGroup.carts);
                    const totalPrice = productPrice - totalDiscountPrice;

                    return { ...productGroup, totalDiscountPrice, productPrice, totalPrice, coupons };
                });

                const carts = productGroups.flatMap(({ carts }) => carts);
                const coupons = this.value.coupons.filter((coupon) => coupon?.shippingCode == shippingGroup?.shippingCode);

                const totalServicePrice = this.$getServicePrice(carts);
                const totalDeliveryPrice = this.$getDeliveryPrice(carts, this.value?.receiver?.geolocation, this.setting, this.userLevel) + this.$getIslandPrice(carts, this.islands, this.value?.receiver?.postcode);
                const shippingCouponPrice = this.$getCouponPrice(carts, coupons, this.value?.receiver?.geolocation, this.setting, this.userLevel);

                const shippingCodeText = this.shippingCodes.find(({ value }) => value == shippingGroup.shippingCode)?.text;
                const isShippingAvailable = this.$getIsShippingAvailable(carts, this.value?.receiver, this.setting);

                const shippingAreas = this.shippingCodes.find(({ value }) => value == shippingGroup.shippingCode)?.areas__available;

                return {
                    ...shippingGroup,
                    totalDeliveryPrice,
                    totalServicePrice,
                    shippingCouponPrice,
                    productGroups,
                    shippingCodeText,
                    isShippingAvailable,
                    shippingAreas,
                    coupons,
                };
            });
        },
        async emit() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await Promise.all([this.$refs["cart-item-coupons"]].flat().map(async (child) => await child.init()));

                let coupons = this.shippingGroups.flatMap(({ coupons, productGroups }) => [...coupons, ...productGroups.flatMap(({ coupons }) => coupons)]);

                const hasNoUncombinableCoupon = !coupons.some((coupon) => !coupon.usage.combinable);

                if (hasNoUncombinableCoupon) coupons = coupons.concat(this.value.coupons.filter((coupon) => ![USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value, USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value].includes(coupon?.usage?.target?.price)));

                this.$emit("input", { ...this.value, coupons });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
