<template>
    <page-section>
        <v-container>
            <h3 class="tit text-center font-tertiary mb-40px mb-md-56px">지금 인기 있는 수전 컬렉션을 만나보세요</h3>
            <v-row class="row--lg">
                <template v-for="item in items">
                    <v-col cols="6" lg="3" :key="item">
                        <v-card tile flat :href="item.link" target="_blank" max-width="400" class="mx-auto">
                            <v-img :src="item.image" class="mb-12px mb-md-16px" />
                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                {{ item.title }}
                            </div>
                            <p class="txt txt--sm grey--text text--darken-3">
                                {{ item.text }}
                            </p>
                            <div class="mt-12px mt-md-16px d-flex align-center">
                                <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                image: "/images/shop/overview/overview-collections-slide-1.jpg",
                title: "Infinity Showers",
                text: "우아한 스파 디자인에 담긴 품질과 기술.",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-2.jpg",
                title: "The Antao",
                text: "자연에서 영감을 받은 부드러운 비대칭 곡선.",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-3.jpg",
                title: "Loop & Friends",
                text: "꿈에 그리던 욕실을 위한 다양한 모양, 크기, 색상의 유연성.",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-4.jpg",
                title: "O.novo, Germany",
                text: "매력적인 가격 대비 성능을 갖춘 현대적인 욕실 디자인.",
                link: "/",
            },
        ],
    }),
};
</script>