import { render, staticRenderFns } from "./icon-check.vue?vue&type=template&id=6e65418b&scoped=true"
import script from "./icon-check.vue?vue&type=script&lang=js"
export * from "./icon-check.vue?vue&type=script&lang=js"
import style0 from "./icon-check.vue?vue&type=style&index=0&id=6e65418b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e65418b",
  null
  
)

export default component.exports