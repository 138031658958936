var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('div', {
    staticClass: "grey lighten-5 py-36px py-md-72px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v(" 더 많은 영감을 "), _c('br'), _vm._v(" 다운로드 하세요 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px ml-20px pa-md-32px ml-md-0",
    attrs: {
      "color": "#fff"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "download-image",
    attrs: {
      "width": "108",
      "height": "152"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "108",
      "src": "/images/shop/overview/overview-download.jpg"
    }
  })], 1)], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs line-height-2"
  }, [_vm._v("2024")]), _c('h3', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("Design & functionality in")]), _c('p', {
    staticClass: "txt txt--xs txt--dark"
  }, [_vm._v("Villeroy & Boch의 새로운 피팅을 만나보세요. 더 많은 정보와 영감을 얻으려면 여기에서 피팅에 대한 최신 브로셔를 다운로드하세요.")]), _c('div', {
    staticClass: "txt txt--xs txt--dark mt-12px mt-md-16px"
  }, [_vm._v("PDF 6 MB")]), _c('v-btn', {
    staticClass: "pa-0 my-20px rounded-0 v-size--xx-small",
    attrs: {
      "text": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("다운로드 받기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-download.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }