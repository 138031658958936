<template>
    <u-dialog v-model="shows" title="쿠폰 사용하기" width="1080" maxWidth="1080" eager v-bind="{ loading }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs: { ...attrs, loading, disabled }, on }" />
        </template>

        <v-row class="mx-n3 my-3">
            <v-col cols="8" class="pa-3">
                <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                <form-table v-model="selected" v-bind="{ items, order, loading }" />
            </v-col>
            <v-col cols="4" class="pa-3">
                <cart-item-coupons-shipping-prices v-bind="{ order, carts, selected }" :coupons="value.coupons" />
            </v-col>
        </v-row>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="btn_tertiary" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="btn_primary" block class="v-size--xx-large" @click="save">저장하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";

import { mapGetters, mapState } from "vuex";
import { btn_primary, btn_tertiary, initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormTable from "@/components/client/shop/coupons/form/form-table.vue";
import CartItemCouponsShippingPrices from "./cart-item-coupons-shipping-prices.vue";

export default {
    components: {
        UDialog,
        FormTable,
        CartItemCouponsShippingPrices,
    },
    props: {
        value: { type: Array, default: () => [] }, // coupons
        order: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        shippingCode: { type: String, default: null },
    },
    data: () => ({
        coupons: [],
        selected: [],

        btn_primary,
        btn_tertiary,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["accessToken", "setting"]),
        items() {
            const { shippingCode } = this;
            return [...(this.coupons || [])].map(this.makeItem);
        },
        disabled() {
            return !this.loading && !this.items?.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        sync() {
            this.selected = this.value.map(this.makeItem);
        },

        emit() {
            this.$emit("input", this.selected);
        },

        makeItem(coupon) {
            const { shippingCode } = this;
            const selectable = !coupon.isUsed && !coupon.isExpired && !coupon.isDeleted;
            coupon = { ...coupon, shippingCode, selectable };
            return { ...coupon, couponPrice: this.$getCouponPrice(this.carts, [coupon], this.order?.receiver?.geolocation, this.setting, this.userLevel) };
        },

        async init() {
            if (!this.accessToken) {
                this.coupons = [];
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupons } = await api.v1.me.coupons.gets({
                    params: { ["usage.target.price"]: USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value, isUsed: false, isExpired: false, isDeleted: false },
                });
                this.coupons = coupons;

                this.selected = coupons.filter((coupon) => coupon.shippingCode == this.shippingCode).map(this.makeItem);
            } finally {
                this.loading = false;
                this.emit();
            }
        },

        async validate() {
            const uncombinableCoupons__this = this.selected.filter((coupon) => !coupon?.usage?.combinable);

            if (0 < uncombinableCoupons__this.length) {
                if (1 < this.selected.length) {
                    alert("중복사용 불가 쿠폰은 단독으로만 사용할 수 있습니다.");
                    this.loading = false;
                    return false;
                }

                const otherCoupons__order = this.order.coupons.filter((coupon) => coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);

                if (0 < otherCoupons__order.length) {
                    const go = confirm("이미 사용중인 다른 쿠폰이 있습니다.\r\n지금 선택하신 쿠폰으로 변경하시겠습니까?");

                    if (!go) {
                        alert("취소하셨습니다.");
                        this.loading = false;
                        return false;
                    }

                    for (const coupon of otherCoupons__order) {
                        const { _id } = coupon;
                        switch (coupon?.usage?.target?.price) {
                            case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                                await api.v1.me.coupons.putShippingCode({ _id, shippingCode: null });
                                break;
                            }
                            case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                                await api.v1.me.coupons.putProduct({ _id, _product: null });
                                break;
                            }
                        }
                    }
                }
            }

            return true;
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            if (!(await this.validate())) return;

            try {
                const { shippingCode } = this;
                const _selected = this.selected.map((coupon) => coupon?._id);
                for (const { _id } of this.coupons.filter((coupon) => !_selected.includes(coupon._id) && coupon.shippingCode == shippingCode)) {
                    await api.v1.me.coupons.putShippingCode({ _id, shippingCode: null });
                }
                for (const _id of _selected) {
                    await api.v1.me.coupons.putShippingCode({ _id, shippingCode });
                }
                this.loading = false;
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
                this.emit();
            }
        },
    },
};
</script>

<style></style>
